import React from "react"
import { Link } from "gatsby"
import { Box, Grid, Typography, Container } from "@material-ui/core"
import Layout from "../components/layout"
import "../styles/homepage-v1.scss"

class LearningCenter extends React.Component {
  render() {
    return (
      <Layout>
        <div className="LinkedPageBackgroundColor">
          <Container maxWidth="md">
            <h1 align="center">Learning Center</h1>

            <div align="center" style={{ margin: "50px 20px 20px 20px" }}>
              <h2 align="center">Articles</h2>
              <ul>
                <li>Are We in A Chinese Democracy</li>
                <li>The Passive Investment Bubble</li>
                <li>Zeros or Zeroes</li>
                <li>Artificial Intelligence _ The COVID Vaccine</li>
                <li>The Powell Put</li>
                <li>Negative Real Rates Are Here</li>
                <li>When Mr. Market trades like a Biotech stock</li>
              </ul>
            </div>
            <div align="center" style={{ margin: "50px 20px 20px 20px" }}>
              <h2 align="center"> White Papers</h2>
              <ul>
                <li>What are ‘alts’?</li>
                <li>What is Long-Short Investing?</li>
              </ul>
            </div>

            <div align="center" style={{ margin: "70px 20px 20px 20px" }}>
              <h2 align="center">Subscribe to our newsletter</h2>
              <iframe
                src="https://99rises.substack.com/embed"
                height="320"
                className="learn-iframe"
                frameborder="0"
                scrolling="no"
              ></iframe>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}
export default LearningCenter
